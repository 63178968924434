<template>

  <div class="header">
    <!-- <img class="logo" src="../assets/AdGenie_logo.png" alt="logo" /> -->
     <a href="/">
    <img class="logo" src="../assets/ficticlogo.png" alt="logo" />
    <!-- <img class="mobile_logo" src="../assets/AdGenie_logo.png" alt="logo" /> -->
    <img class="mobile_logo" src="../assets/ficticlogo.png" alt="logo" /> </a>
    
    <!-- <img class="logotext" src="../assets/adgenietext.png" alt="logo"/>" -->


    <!-- <div class="tags tags_mobile"> -->

      <!-- <Tag value="Limited Preview" class="betatag" severity="danger" rounded></Tag> -->

      <!-- <div class="tokeninfo" @click="showPricing">
        <div class="tokentext"> {{ this.tokensLeft }} </div>

        <svg class="icon" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125">
          </path>
        </svg>
      </div> -->
    <!-- </div> -->

    <Menubar :model="items">
      <template #item="{ item, props, hasSubmenu, root }">
        <a class="flex align-items-center" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <!-- <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" /> -->
          <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{
        item.shortcut }}</span>
          <i v-if="hasSubmenu"
            :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
        </a>
      </template>
      <!-- <template #end>
          <div class="flex align-items-center gap-2">
              <InputText placeholder="Search" type="text" class="w-8rem sm:w-auto" />
              <Avatar image="/images/avatar/amyelsner.png" shape="circle" />
          </div>
      </template> -->
    </Menubar>

    <!-- <div class="tags"> -->
      <!--
          <Tag value="Limited Preview" class="betatag" severity="danger" rounded></Tag>
      -->
      <!-- <div class="tokeninfo" @click="showPricing">
        <div class="tokentext"> {{ this.tokensLeft }} </div>

        <svg class="icon" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125">
          </path>
        </svg>
      </div> -->
    <!-- </div> -->
  </div>

</template>

<script>
import Menubar from 'primevue/menubar';
/*import Tag from 'primevue/tag';*/
import { event } from 'vue-gtag';
import * as amplitude from '@amplitude/analytics-browser';


export default {
  name: 'HeaderComp', // replace with your component name
  data() {
    return {
      items: [],
      jobs: [],
      tokensLeft: 0,
      products: [],
      adgenieuserid: ""



    };
  },
  components: {
    Menubar
  },
  emits: ["selectJob", "tokensLeftChange", "showPricing","adgenieuseridFound","selectProduct"],
  props: ["jobcounter", "emailsubmission", "apptokensLeft"],
  watch: {
    jobcounter: {
      handler(val) {
        console.log("total jobs changed", val);
        this.fetchOldJobs();
      }
    },
    apptokensLeft: {
      handler(val) {
        console.log("tokens left changed in header", val);
        this.tokensLeft = val;
      }
    },
    emailsubmission: {
      handler(val) {
        console.log("email submitted in header", val);
        amplitude.track('AwardedTokens', 50);
        fetch("https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/addtoken?tokenstoadd=50").then(response => {
          console.log(response);
          this.fetchOldJobs();
        });
      }
    }
  },

  methods: {

    async fetchOldJobs(onmount = false) {
      console.count("Fetching old jobs");
      const url = new URL("https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/userinfo");

      // Create a URLSearchParams object
      const params = new URLSearchParams();
      if (this.adgenieuserid != null && this.adgenieuserid != "") {
        console.log("userid found", this.adgenieuserid)
        params.append('userid', this.adgenieuserid);
      }
      else {
        console.log("No userid found")

      }


      // Append the parameters to the URL
      url.search = params;
      const response = (await fetch(url).then(response => response.json())
        .then(data => {
          this.jobs = data.jobs;
          this.tokensLeft = data.tokensLeft;
          this.$emit('tokensLeftChange', data.tokensLeft);
          this.setCookie('adgenieuserid', data.userid, 365);
          console.log(data);
          console.log(data.tokensLeft)
          var prodlist = this.jobs.map(job => ({ label: job.product, key: job.jobid, command: this.selectJob }));
          console.log(this.prodlist);
          // this.products=prodlist.map(label => ({ label: label }));
          this.items[3].items = prodlist;
          if (onmount == true && prodlist.length > 0) {
            console.log("selecting job", prodlist[0].key)
          //   if (!(document.referrer.includes("fictic") || window.location.href.includes("fictic"))){
           // this.$emit('selectJob', prodlist[0].key);
          // }
          }
          // this.items[4]



          return data;
        }));
      console.log(response);

    },

    showPricing() {
      console.log("Show Pricing")
      amplitude.track('TokenClicked');
      event('PricingClicked');
      // this.$router.push('/pricing');
      this.showPricingDialog = Math.floor(new Date().getTime() / 1000);
      this.$emit('showPricing', this.showPricingDialog);

    },
    getCookie(name) {
      console.log("getting cookie", document.cookie)
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    selectJob(events) {
      amplitude.track('PreviousJobSelected', { "selectedjob": events.item.key });
      event('PreviousJobSelected', { "selectedjob": events.item.key });
      console.log("selectedJob is", events.item.key);
      this.$emit('selectJob', events.item.key);
    }

    // Your methods go here
  },
  created() {
    this.adgenieuserid = this.getCookie('adgenieuserid');
    if (this.adgenieuserid != null) {
      this.$emit('adgenieuseridFound', this.adgenieuserid);
    }
    
    console.log("adgenieuserid is ", this.adgenieuserid);
    var docproduct = { "label": "Doc To Video" ,command: () => {amplitude.track('Product Chosen',{"Product": "Doc To Video" }); this.$emit('selectProduct', "DocToVideo");} }
    var vidproduct = { "label": "Promo Videos",command: () => {amplitude.track('Product Chosen',{"Product": "Promo Videos" }); this.$emit('selectProduct', "VideoPromos");} }
    var vidads = { "label": "Video Ads" ,command: () => {amplitude.track('Product Chosen',{"Product": "Video Ads" }); this.$emit('selectProduct', "VideoAds");}}
    var imageads = { "label": "Image Ads",command: () => {amplitude.track('Product Chosen',{"Product": "Image Ads" }); this.$emit('selectProduct', "ImageAds");} }
    var availablesoon = { "label": "Coming Soon" }
    var homeitem = { "label": "Home", "to": "/" }
    var productitem = { "label": "Products", "to": "/product", "items": [docproduct,vidproduct,vidads,imageads], "hasSubmenu": true,  }
    // var pricingitem = { "label": "PricinDg", "hasSubmenu": false, "command": this.showPricing }
    var signupitem = { "label": "Signup", "to": "/signup", "items": [availablesoon], "hasSubmenu": true }
    var assetsitem = { "label": "My Assets", "to": "/login", "items": [], "hasSubmenu": true }

    this.items = [homeitem, productitem, signupitem,assetsitem]
    this.adgenieuserid
  },

  mounted() {
    // Code to run when
    this.fetchOldJobs(true)
  }




};
</script>

<style scoped>
.logo {
  margin-right: auto;
  height: 50px;
  width: 100px;
}

.mobile_logo {
  margin-right: auto;
  display: none;
  height: 50px;
  width: 100px;
}

.tags_mobile {
  display: none;
}


.p-menubar {
  background: transparent;
  border: none;
}

.logotext {
  display: inline;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 2rem;
  color: rgb(241, 158, 56);
  top: 5.5rem;
  font-weight: 800;
  height: 30px;
  width: 100px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

}

.tags {
  margin-left: auto;

}

.tokeninfo {
  cursor: pointer;

}

.tokeninfo svg {
  cursor: pointer;
  color: #FFBF00;

}

.tokentext {
  position: relative;
  justify-content: center;
  font-size: large;
  margin-right: 0.5em;
  display: inline-block;
  color: #FFF;

}

.betatag {
  position: relative;
  font-size: large;
}



@media(max-width:960px) {

  .logo,
  .tags {
    display: none;
  }

  .mobile_logo,
  .tags.tags_mobile {
    display: block;
  }

  .tags.tags_mobile {
    margin-left: auto;
    margin-right: auto;
  }

  .p-menubar {
    margin-left: auto;
  }
}

/* Your CSS goes here */
</style>
